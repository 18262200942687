// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

#eventStockImage{
    width: 100%;
}
#mediaType{
    width: 100%;
}
#selectedUsers{
    width: 100%;
}
#localLodgeAdmin{
    width: 100%;
}
#grandLodgeAdmin{
    width: 100%;
}

.select-box{
    width: 100%;
}